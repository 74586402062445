import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'react-scroll-section';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Home() {
    const { t } = useTranslation();
    const [FromSub, setFromSub] = useState({});
    const [Error, setError] = useState('')
    const [Sendt, setSendt] = useState(false)
    const handleChange = (event) => {
        setFromSub({
            email: event.target.value,
            name: 'From Landing',
            message: 'Subscribed'
        });
    }

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (

            <div className="PartnersControll NextSlide" onClick={onClick}
            >
                <img src="/assets/next.svg" />
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (

            <div className="PartnersControll BackSlide" onClick={onClick}>
                <img src="/assets/back.svg" />
            </div>
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {

                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };

    const handleSubmit = (event) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(FromSub.email)) {
            fetch('https://imperiumdrive.com/assets/contact/handler.php', {
                method: 'POST',
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify(FromSub)
            }).then(function (response) {
                setError(false)
                setSendt(true)
                return response.json();
            });
        } else {
            setError('Email must be a valid email address')
        }


        //event.preventDefault();
    }
    return (
        <div className="homePage">
            <Section className={"section"} id="home">
                <div className="video-overlay"></div>
                <div className="hero-video">
                <div className='Launching'>
                     <span>Enabling autonomy through teleoperation</span>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<video autoplay loop muted playsinline>
                            <source
                                    src="/assets/video/fetch_demo-500k.webm"
                                    type="video/webm"
                                />
                                <source
                                src="/assets/video/fetch_demo-500k.mp4"
                                type="video/mp4"
                            />
                        </video>`,
                        }}
                    />
                </div>
                {/* <div className={"slider pt-250 color-fff"}>
                    <div className={"sliderBox"}>
                        <div className={"sliderBoxTitle"}>Safe Teleoperation</div>
                        <div className={"sliderBoxSub "}>
                            Remote driving solution for fleet operators to drive their vehicles
                            safely from anywhere
                        </div>
                    </div>
                </div> */}
                {/* <div className={"subscribeBox"}>
                    <div className={"subscribeBoxHeader"}>
                        <strong>Subscribe to our newsletter!</strong>
                        {Error && <div className='emailError'>{Error}</div>}
                        {Sendt && <div className='emailSuccess'>your email successfully saved!</div>}
                    </div>
                    <div className={"subscribeBoxBody"}>
                        <div className="subscribeBoxInput">
                          <input type="text"  name="email" onChange={handleChange}  value={FromSub.email ? FromSub.email  : ''} placeholder="E-Mail address" />
                        </div>
                        <div className="subscribeBoxSend" onClick={()=>{handleSubmit()}}>
                            Send
                            <img src="/assets/send.svg" />
                        </div>
                    </div>
                </div> */}
            </Section>
            <Section className={"section sectionApplication bg-fff pt-250"} id="app">
                <div className={"sectionCols"}>
                    <div className={"sectionColsTitle w-400"}>APPLICATIONS</div>
                    <div className={"sectionColsSub w-600"}>
                        Unlocking the benefits of autonomous mobility today
                    </div>

                    <div className={"AppItemsFa"}>
                        <div className={"AppItem"}>
                            <div className="appImg">
                                <img src="/assets/last_mile_delivery.svg" />
                            </div>
                            <strong>Last mile delivery</strong>
                            <span>
                                Our solution helps fleet operators to deploy last-mile delivery
                                robots of any size at scale. Today.
                            </span>
                            <span>
                                Our modular software stack enables flexible integration of
                                teleoperation on different robot platforms. Our in-house expertise
                                in autonomous control ensures easy integration with an existing AV
                                stack.
                            </span>
                        </div>
                        <div className={"AppItem"}>
                            <div className="appImg">
                                <img src="/assets/car_sharing.svg" />
                            </div>
                            <strong>Shared Mobility</strong>
                            <span>
                                Our technology enables car sharing operators to increase their
                                utilization significantly, reduce their fleet management costs and
                                achieve profitability.
                            </span>
                            <span>
                                By performing remote relocations and vehicle deliveries, fleet
                                operators can increase their service area, offer more convenience
                                and unlock demand for car-sharing in urban areas.
                            </span>
                        </div>
                        <div className={"AppItem"}>
                            <div className="appImg">
                                <img src="/assets/autonomous_vehicle.svg" />
                            </div>
                            <strong>Autonomous vehicles</strong>
                            <span>
                                Autonomous vehicles will need human supervision or intervention for
                                many years to come.
                            </span>
                            <span>
                                Autonomous fleet operators will be required by law to demonstrate
                                safe and reliable remote operations capability before any commercial
                                deployments on public roads take place. Our solution provides this
                                capability to OEMs, AV system developers and fleet operators alike.
                            </span>
                        </div>
                    </div>
                </div>
            </Section>
            <Section className={"section sectionTechFa bg-fff pt-250"} id="tech">
                <div className={"sectionCols sectionTech"}>
                    <div className={"sectionColsTitle"}>TECHNOLOGY</div>
                    <div className={"sectionColFa"}>
                        <div className={"sectionCol techItem"}>
                            <strong className={"sectionColTitle"}>AI-based Link Prediction</strong>
                            <div className="sectionColDesc">
                                We've developed algorithms that predict with more than 95% accuracy
                                the end-to-end network behaviour using all available parameters from
                                cellular networks.
                                <br />
                                <br />
                                These predictions enable safe teleoperation by enabling in-time
                                adaptation of the streaming and control systems leading to robust
                                and safe teleoperation in uncontrolled environments.
                            </div>
                            {/* <div className="readMoreBtn">
                                read more <img src="/assets/arrow-right.svg" />
                            </div> */}
                        </div>
                        <div className={"sectionCol techItem"}>
                            <strong className={"sectionColTitle"}>Link-aware Streaming</strong>
                            <div className="sectionColDesc">
                                Our proprietary streaming engine adapts to network variations,
                                ensuring timely transmission of the most critical information from
                                the vehicle.
                                <br />
                                <br />
                                Utilizing this intelligent streaming technology ensures that
                                critical information about the driving environment such as lane
                                boundaries, pedestrians etc. are transmitted reliably to the remote
                                operator.
                            </div>
                            <div className="readMoreBtn">
                                read more <img src="/assets/arrow-right.svg" />
                            </div>
                        </div>
                        <div className={"sectionCol techItem"}>
                            <strong className={"sectionColTitle"}>Link-aware Control</strong>
                            <div className="sectionColDesc">
                                We've developed a novel active safety system that uses advanced
                                link-aware control algorithms to ensure safe and smooth vehicle
                                operation by listening to the network prediction and streaming
                                engine.
                                <br />
                                <br />
                                Our link-aware controller gracefully handles extreme network
                                conditions such as a sudden loss of signal or highly unstable links
                                by executing safety maneuvers that ensure vehicle safety at all
                                times.
                            </div>
                            <div className="readMoreBtn">
                                read more <img src="/assets/arrow-right.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <Section className={"section sectionNews bg-fff pt-250 hv200"} id="news">
                <div className={"sectionCols"}>
                    <div className={"sectionColsTitle w-400"}>NEWS</div>
                    <div className={"sectionColsSub w-400"}>
                        Keep up-to-date with our latest news
                    </div>
                    <div className={"NewsFa"}>
                        <div className="newsCol newsColSingle ">
                            <div className={"NewsItem"}>
                                <div className="NewsItemImg bbc">
                                    <img src="/assets/news/bbc.png" />
                                </div>
                                <div className="NewsItemBody">
                                    <strong>NEWS</strong>
                                    <div className="NewsItemTitle">BBC</div>
                                    <span>
                                    We had the pleasure of demonstrating our Fetch car-hailing concept to BBC.
                                    </span>
                                    <a
                                        target="_blank"
                                        href="https://www.bbc.co.uk/news/technology-59859587 "
                                        className="moreNews"
                                    >
                                        <img src="/assets/arrow-right.svg" />
                                        {/*  */}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="newsCol">
                            <div className={"NewsItem"}>
                                <div className="NewsItemImg">
                                    <img src="/assets/news/the_times.png" />
                                </div>
                                <div className="NewsItemBody">
                                    <strong>NEWS</strong>
                                    <div className="NewsItemTitle">The Times</div>
                                    <span>
                                    A very nice feature about Fetch in The Times.
                                    </span>
                                    <a
                                        target="_blank"
                                        href="https://www.thetimes.co.uk/article/a29e2bb8-73e7-11ec-89e9-22d3d9c31ba2"
                                        className="moreNews"
                                    >
                                        <img src="/assets/arrow-right.svg" />
                                    </a>
                                </div>
                            </div>
                            <div className={"NewsItem"}>
                                <div className="NewsItemImg">
                                    <img src="/assets/news/5_News.png" />
                                </div>
                                <div className="NewsItemBody">
                                    <strong>NEWS</strong>
                                    <div className="NewsItemTitle">5 News</div>
                                    <span>
                                    We were featured on Channel 5 News <div>on Jan 12, 2022</div>
                                    </span>
                                    <a
                                        target="_blank"
                                        href="https://www.youtube.com/watch?v=19V5ONnoask"
                                        className="moreNews"
                                    >
                                        <img src="/assets/arrow-right.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a
                        href="https://www.linkedin.com/company/imperium-drive-ltd/posts/?feedView=all"
                        className="readMoreFull readMoreFullBlack"
                        target="_blank"
                    >
                        Read more <img src="/assets/arrow-right.svg" />
                    </a>
                </div>
            </Section>
            <Section className={"section sectionTeam bg-fff pt-250"} id="team">
                <div className={"sectionCols"}>
                    <div className={"sectionColsTitle"}>TEAM</div>
                    <div className={"sectionColsSub"}>Meet our team</div>
                    <div className={"sectionColFa"}>
                        <div className="CaruselItemsFa">
                            <div className="CaruselItems">
                                <Slider {...settings}>
                                    <div className={"sectionCol teamUser"}>
                                        <div className="imgBox">
                                            <img src="/assets/kave.jpeg" />
                                        </div>
                                        <div className="teamUserData">
                                            <div className="teamUserDataRole">CEO</div>
                                            <div className="teamUserDataName">Koosha Kaveh</div>
                                            <div className="teamUserDataDesc">
                                                2x Founder. Passionate about taking deep tech ideas
                                                to the market. Previously worked as a strategy
                                                consultant for 10 years.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"sectionCol teamUser"}>
                                        <div className="imgBox">
                                            <img src="/assets/sandip.jpeg" />
                                        </div>
                                        <div className="teamUserData">
                                            <div className="teamUserDataRole">CTO</div>
                                            <div className="teamUserDataName">
                                                Sandip Gangakhedkar
                                            </div>
                                            <div className="teamUserDataDesc">
                                                Wireless systems expert with +10 years of experience
                                                working for companies like Huawei, Nokia and Bell
                                                Labs.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"sectionCol teamUser"}>
                                        <div className="imgBox">
                                            <img src="/assets/navid.jpeg" />
                                        </div>
                                        <div className="teamUserData">
                                            <div className="teamUserDataRole">CPO</div>
                                            <div className="teamUserDataName">
                                                Dr. Navid Nourani Vatani
                                            </div>
                                            <div className="teamUserDataDesc">
                                                15 years of experience developing AI systems and
                                                Autonomous Vehicles at companies like Volkswagen,
                                                MAN Trucks and Siemens.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"sectionCol teamUser"}>
                                        <div className="imgBox">
                                            <img src="/assets/filipe.jpeg" />
                                        </div>
                                        <div className="teamUserData">
                                            <div className="teamUserDataRole">
                                                Robotics Engineer
                                            </div>
                                            <div className="teamUserDataName">
                                                Filipe Cerveira Pinto
                                            </div>
                                            <div className="teamUserDataDesc">
                                                Robotics Engineer with a Masters in Electrical and
                                                Computer Engineering and 3 years of experience
                                                developing software for autonomous robots. Always
                                                fond of a good challenge.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"sectionCol teamUser"}>
                                        <div className="imgBox">
                                            <img src="/assets/javad.jpg" />
                                        </div>
                                        <div className="teamUserData">
                                            <div className="teamUserDataRole">
                                                Embedded SW Engineer
                                            </div>
                                            <div className="teamUserDataName">Javad Rahimi</div>
                                            <div className="teamUserDataDesc">
                                                Embedded Software Engineer with 7 years of
                                                experience in the field of embedded systems, digital
                                                circuit design and Linux based application
                                                development.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"sectionCol teamUser"}>
                                        <div className="imgBox">
                                            <img src="/assets/default_profile_picture.jpeg" />
                                        </div>
                                        <div className="teamUserData">
                                            <div className="teamUserDataRole">Join Us</div>
                                            <div className="teamUserDataName">You</div>
                                            <div className="teamUserDataDesc">
                                                We are always looking for exceptional individuals to
                                                join our team. We'd love to hear from you and
                                                together tailor the right position for you.
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
         
            <Section className={"section sectionPartenrsFa bg-fff pt-250"} id="partners">
                <div className={"sectionCols"}>
                    <div className={"sectionColsTitle"}>PARTNERS</div>
                    <div className={"sectionColsSub"}>We are supported by</div>
                    <div className={"PartnersFa"}>
                        <div className="PartnersItems">
                            <Slider {...settings}>
                                <div className="PartnerItem">
                                    <img src="/assets/partners/PlugAndPlay.svg" />
                                </div>
                                <div className="PartnerItem">
                                    <img src="/assets/partners/Techstars.svg" />
                                </div>
                                <div className="PartnerItem">
                                    <img src="/assets/partners/EF.svg" />
                                </div>
                                <div className="PartnerItem">
                                    <img src="/assets/partners/CCAV.svg" />
                                </div>
                                <div className="PartnerItem">
                                    <img src="/assets/partners/nvidia.svg" />
                                </div>
                                <div className="PartnerItem">
                                    <img src="/assets/partners/Wayra.svg" />
                                </div>
                                <div className="PartnerItem">
                                    <img src="/assets/partners/zenzic.svg" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    );
}

export default Home;
