import React, { useEffect, useState } from 'react'

function VerifyEmail() {
    const [OpenSlide, setOpenSlide] = useState(false);
    function slidClick(prop) {
        if (prop === OpenSlide) {
            setOpenSlide(false)
        } else {
            setOpenSlide(prop)
        }
    }
    function slidClass(prop) {
        if (prop === OpenSlide) {
            return 'CareersCatItems CareersCatItemsOpen'
        } else {
            return 'CareersCatItems'
        }
    }
    return (
        <section className={"content sectionCareesFa"} >
            <div className={"sectionColsTitle w-400"}>CAREERS</div>
            <div className={"sectionColsSub w-400"}>Our open positions: Join Us!</div>
            <div className={"sectionColsTitle w-400"}>No openings available at the moment.</div>
            {/* <div className="CaruselItems">
                <div className='CareersCat'>
                    <div className='CareersCatTitle' onClick={() => { slidClick("Engineering") }}>
                        <img src='/assets/arrow-right.svg' />Engineering</div>
                    <div className={slidClass('Engineering')}>


                   

                        <a href="assets/docs/Senior Video Streaming R&D engineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Senior Video Streaming R&D engineer</strong>
                            <span>Job ID: 211122-01</span>

                        </a>
                        <a href="assets/docs/Networks R&D engineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Networks R&D engineer</strong>
                            <span>Job ID: 220501-01</span>

                        </a>
                        <a href="assets/docs/Software engineer - Multimedia platforms.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Software engineer - Multimedia platforms</strong>
                            <span>Job ID: 220501-02</span>

                        </a>
                        <a href="assets/docs/2022001-01_HMIEngineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>HMI Engineer</strong>
                            <span>Job ID: 220101-01</span>

                        </a>
                        
                        <a href="assets/docs/20210503-03_DevOpsEngineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Cloud DevOps Engineer</strong>
                            <span>Job ID: 210503-03</span>

                        </a>
                        <a href="assets/docs/Embedded software engineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Embedded Software Engineer</strong>
                            <span>Job ID: 220518-01</span>

                        </a>
                        <a href="assets/docs/20210503-01_SoftwareTestEngineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Software Test Engineer</strong>
                            <span>Job ID: 210503-01</span>

                        </a>

                        <a href="assets/docs/20220411-03_RoboticsEngineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Robotics Engineer</strong>
                            <span>Job ID: 210503-02</span>

                        </a>



                        <a href="assets/docs/20220411-01_ComputerVisionEngineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Computer Vision Engineer</strong>
                            <span>Job ID: 220411-01</span>

                        </a>

                        <a href="assets/docs/20220411-02_SeniorAutonomousDrivingEngineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Senior Autonomous Driving Engineer</strong>
                            <span>Job ID: 210503-02</span>

                        </a>

                        <a
                            href="assets/docs/20220415-01_ControlsEngineer.pdf" target="_blank" className={"CareersItem"}>
                            <strong>Controls Engineer</strong>
                            <span>Job ID: 220415-01</span>

                        </a>




                    </div>
                </div>
                <div className='CareersCat'>
                    <div className='CareersCatTitle' onClick={() => { slidClick("Safty") }}>
                    <img src='/assets/arrow-right.svg' />Systems and Safety</div>
                    <div className={slidClass('Safty')}>

                    <a href="assets/docs/2022017-01_FunctionalSafetyEngineer.pdf" target="_blank" className={"  CareersItem"}>
                            <strong>Functional Safety Engineer</strong>
                            <span>Job ID: 220117-01</span>

                        </a>


                    </div>
                </div>

            </div> */}

        </section>


    );
}
export default VerifyEmail;
