import React from 'react'
import {Link} from 'react-router-dom'
import {
    ScrollingProvider,
    useScrollSection,
    Section,
  } from 'react-scroll-section';
   
function Footer() {
    const creers = useScrollSection('creers');
    const about = useScrollSection('team');
    const news = useScrollSection('news');

    return (
        <footer>
            <div className="footerInfo">
                <div className="footerBody">
                    <div className="footerCol flex-1">
                        <div className="w-200">
                            <img src="/assets/White.svg" className="footerLogo" />
                            <span className="footerLogoDesc">
                                Unlocking the benefits of autonomous mobility today.
                            </span>
                            <a href="https://www.linkedin.com/company/imperium-drive-ltd/">
                                <img src="/assets/social-linkedin-circle-512.png" />
                            </a>
                        </div>
                    </div>
                    <div className="footerCol">
                        <div className="title">Useful links</div>
                        <Link to="" onClick={creers.onClick}>
                            Careers
                        </Link>
                        <Link to="" onClick={about.onClick}>
                            About Us
                        </Link>
                        <Link to="" onClick={news.onClick}>
                            News
                        </Link>
                    </div>
                    <div className="footerCol">
                        <div className="title">Contact Info</div>
                        <span>
                            <img src="/assets/mail.svg" />
                            <a className='emailFooter'
                                href="mailto:info@imperiumdrive.com?subject=Contact from ImperiumDrive.com"
                            >
                                info@imperiumdrive.com
                            </a>
                        </span>
                        <span>
                            <img src="/assets/map-pin.svg" />
                            London, United Kingdom
                        </span>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <span>Copyright © 2019-2022 All rights reserved</span>
                <a href="https://www.imperiumdrive.com">www.imperiumdrive.com</a>
            </div>
        </footer>
    );
}
export default Footer;
