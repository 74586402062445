import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function Contact() {
    const [FromSub, setFromSub] = useState({});
    const [Error, setError] = useState('')
    const [Sent, setSendt] = useState(false)
    const handleChange = (event) => {
        setFromSub({
            ...FromSub,
            [event.target.name]: event.target.value,
        });
    }

    const handleSubmit = (event) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!FromSub.name) {
            setError('Name is required ')
            setSendt(false)
            return ''

        }
        if (!FromSub.subject) {
            setError('Subject is required ')
            setSendt(false)
            return ''
        }
        if (re.test(FromSub.email)) {
            const formData = new FormData();
            for (const key in FromSub) {
                if (FromSub[key]) {
                    formData.append(key, FromSub[key]);
                }
            }
            fetch('https://imperiumdrive.com/v.0/assets/contact/handler.php', {
                method: 'POST',
                headers: {
                    "Access-Control-Allow-Origin": "*"
                },
                body: formData
            }).then(function (response) {

                const res = response.json();
                console.log(res.result)
                if (res.result === "validation_failed") {
                    var e = '';
                    for (const key in res.errors) {
                        if (res.errors[key]) {
                            e += key + ":" + res.errors[key] + " , ";
                        }
                    }
                    setError(e)
                    setSendt(false)
                } else {
                    setError(false)
                    setSendt(true)
                    setFromSub({})
                }
            }).catch((e) => {
                setError('Failed')
                setSendt(false)
            });
        } else {
            setError('Email must be a valid email address')
            setSendt(false)

        }


        //event.preventDefault();
    }

    return (
        <section className={"singlePage"} >
            <header>
                <div className="title">Contact</div>
            </header>
            <div className={"singleData"}>

                {/* <div className={"sectionCols"}>
                        <div className={"sectionColsTitle"}>CONTACT</div>
                        <div className={"sectionColsSub"}>Our contacts</div>
                        <div className={"ContactCols"}>
                            <div className='ContactCol'>
                                <div className='imgboxContact'>
                                    <img src="/assets/mail-W.svg" />
                                </div>
                                <div className='title'>Mail</div>
                                <strong className=''>example@yahoo.com</strong>
                                <strong className=''>example@yahoo.com</strong>
                            </div>
                            <div className='ContactCol'>
                                <div className='imgboxContact'>
                                    <img src="/assets/phone-W.svg" />
                                </div>
                                <div className='title'>Phone</div>
                                <strong className=''>+0 320 422 4254</strong>
                                <strong className=''>+0 342 552 523</strong>
                            </div>
                            <div className='ContactCol'>
                                <div className='imgboxContact'>
                                    <img src="/assets/map-pin-W.svg" />
                                </div>
                                <div className='title'>Location</div>
                                <strong className=''>Main Str Chicago Ilinois</strong>
                                <strong className=''>Main Street New York City</strong>
                            </div>

                        </div>
                </div> */}
                <div className={"sectionCols"}>
                    <div className={"sectionColsTitle"}>CONTACT</div>
                    <div className={"sectionColsSub"}>Contact us</div>
                    {Error && <div className='Error'>{Error}</div>}
                    {Sent && <div className='Success'>Your email was sent successfully!</div>}
                    <div className={"ContactFormFa"}>
                        <div className='ContactTextarea'>

                            <textarea placeholder='Description' name='message' onChange={handleChange} value={FromSub.message ? FromSub.message : ''} ></textarea>
                        </div>
                        <div className='ContactInputs'>
                            <input type='text' placeholder='Subject' name="subject" onChange={handleChange} value={FromSub.subject ? FromSub.subject : ''} />
                            <input type='text' placeholder='Name / Surname' name="name" onChange={handleChange} value={FromSub.name ? FromSub.name : ''} />
                            <input type='text' placeholder='Email' name="email" onChange={handleChange} value={FromSub.email ? FromSub.email : ''} />
                            <div className='SendBtn' onClick={() => { handleSubmit() }}>Send  <img src="/assets/send-W.svg" /></div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className={"contactinfo"}>

                <h2>Media Enquiries </h2>
                <h3>For all press/media enquiries please contact Matt Moore on <span>matt.moore@pfpr.com</span></h3>
            </div>
        </section>


    );
}
export default Contact;
