import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import config from '../config.json'
import {
    ScrollingProvider,
    useScrollSection,
    Section,
  } from 'react-scroll-section';
   
export default function Header() {
    const {t,i18n} = useTranslation();
    const [OpenMenu, setOpenMenu] = useState(false);
    const homeSection = useScrollSection('home');
    const Applications = useScrollSection('app');
    const tech = useScrollSection('tech');
    const News = useScrollSection('news');
    const About_Us = useScrollSection('team');
    const Careers = useScrollSection('careers');
    
    return (
        <>
            <menu>
                <div className={'menu-mobile'} onClick={()=>{setOpenMenu(!OpenMenu)}}><img src={'/assets/menu.png'} /> </div>
                <Link to="/"> <img src={'/assets/White.svg'}  className={'headerLogo'}/></Link>
                <div className='MenuCenter'></div>
                <ul className={OpenMenu ? "menuShow" : ""}>
                    <li onClick={() => {homeSection.onClick();setOpenMenu(false)}} className={homeSection.selected && "active"} ><Link to='/' >Home</Link></li>
                    <li onClick={() => {Applications.onClick();setOpenMenu(false)}}  className={Applications.selected && "active"} ><Link to='/'  >Applications</Link></li>
                    <li onClick={() => {tech.onClick();setOpenMenu(false)}} className={tech.selected && "active"} ><Link to='/' >Technology</Link></li>
                    <li onClick={() => {News.onClick();setOpenMenu(false)}}  className={News.selected && "active"} ><Link to='/'  >News</Link></li>
                    <li onClick={() => {About_Us.onClick();setOpenMenu(false)}}  className={About_Us.selected && "active"} ><Link to='/'>  Team</Link></li>
                    <li className={""} ><a href='/careers' >Careers</a></li>
                    <li className={""} ><a href='contactUs' > Contact Us</a></li>
                </ul>
            </menu>
        </>
    )
}
