import React ,{useEffect, useState} from 'react'
import {Link,useParams} from 'react-router-dom'

function VerifyEmail() {
    const [verify, setVerify] = useState(false);
    const prop = useParams();

    const  handleSubmit = () => {
          const formData = new FormData();
          formData.append('key', prop.key);
            fetch('https://staging-api.imperiumdrive.com/auth/account-confirm-email/', {
                method: 'POST',
                body : formData
            }).then(function(response) {
                setVerify('success')
            
            }).catch((er)=>{
                setVerify('failed')
            });
    
    }
    useEffect(()=>{
        handleSubmit()
    },[])
    return (
        <section className={"alertSection"} >
            {!verify && <div className='loadingAlert'>Loading...</div>}
            {verify === "success" && <div className='successAlert'>your email has been successfully verified</div> }
            {verify === "failed" && <div className='wrongAlert'>we can't verify your email now hulu</div> }
    </section>
  
          
    );
}
export default VerifyEmail;
