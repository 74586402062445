import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {
    ScrollingProvider,
    useScrollSection,
    Section,
  } from 'react-scroll-section';
import ReactGA from 'react-ga';
import Header from './pages/header'
import Footer from './pages/footer'
import Home from './pages/Home';
import ContactUs from './pages/contactUs';
import Careers from './pages/Careers';

import VerifyEmail from "./pages/VerifyEmail"
import './i18n';
ReactGA.initialize('UA-139340429-1');
ReactGA.pageview(window.location.pathname + window.location.search);
const Routing = () => {
    return(
        <Router>
            <ScrollingProvider>
            <Header/>
            <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contactUs" component={ContactUs} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/verify/email/:key" component={VerifyEmail} />

            </Switch>

            <Footer/>
            </ScrollingProvider>

</Router>
    )
}


ReactDOM.render(
    <React.StrictMode>
        <Routing />
    </React.StrictMode>,
    document.getElementById('root')
);
